.env_content {
  margin: 0 auto;
  width: 22.4rem;
}

.env_content .title {
  margin: 1.333333rem 0 1.866667rem 0;
  text-align: left;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.env_content .con {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.env_content .con .left p {
  margin-bottom: 0;
  font-size: calc(0.373333rem*1.18);
  color: #666666;
}

.env_content .con .left.down {
  display: flex;
  flex-direction: column-reverse;
}

.env_content .con .right p {
  margin-bottom: 0;
  font-size: calc(0.373333rem*1.18);
  color: #666666;
}

.env_content .con .right.down {
  position: absolute;
  bottom: 0;
  left: calc(6.4rem * 1.18);
}

.env_content .con.y {
  top: calc(-4rem * 1.18);
}

.env_content .con.last {
  top: calc(-2.666667rem * 1.18);
  margin: 0.933333rem 0;
}

.env_content .cons {
  position: relative;
  top: calc(-2.666667rem * 1.18);
}

.env_content .cons .top {
  margin-bottom: 0;
}

.env_content .cons .top .img {
  width: 100%;
  height: 10.266667rem;
  border-radius: 0.026667rem;
  object-fit: cover;
}

.env_content .cons .bot {
  margin-top: 0.533333rem;
}

.env_content .cons .bot p {
  margin-bottom: 0;
  font-size: calc(0.373333rem*1.18);
  color: #666666;
}

.circle {
  width: calc(8rem * 1.18);
  height: calc(8rem * 1.18);
  border-radius: 100%;
}

.rec {
  width: calc(5.866667rem * 1.18);
  height: calc(8.88rem * 1.18);
  border-radius: 0.026667rem;
}

.env_mobile {
  padding: 0 0.533333rem;
  margin-bottom: 1.066667rem;
}

.env_mobile .title {
  margin: 1.333333rem 0 1.866667rem 0;
  text-align: left;
  font-weight: 700;
  font-size: 0.426667rem;
  color: #0a62a7;
}

.env_mobile p {
  margin-bottom: 0;
  font-size: 0.426667rem;
  color: #000000;
}

.env_mobile .img1 {
  margin-bottom: 0.266667rem;
  width: 100%;
  height: 4.8rem;
  object-fit: cover;
}

.env_mobile .circle {
  margin-top: 0.533333rem;
  width: 8rem;
  height: 8rem;
}

.env_mobile .rec {
  margin-top: 0.533333rem;
  margin-bottom: 1.066667rem;
  width: 5.866667rem;
  height: 8.88rem;
  border-radius: 0.026667rem;
}

.env_mobile .right {
  margin-bottom: 1.066667rem;
  text-align: right;
}

.env_mobile .right p {
  display: inline-block;
  width: 5.866667rem;
  text-align: left;
}