.resume {
  margin: 0 auto;
  width: 22.4rem;
}

.resume .img_wrapper1 {
  display: flex;
  margin-top: 0.533333rem;
}

.resume .img_wrapper1 .img_desc {
  margin-top: 0.106667rem;
  text-align: center;
  font-size: 0.293333rem;
  font-weight: normal;
  color: #000000;
  line-height: 0.24rem;
}

.resume .img_wrapper1.pc .gzh {
  margin-right: 4rem;
}

.resume .img_wrapper1.mobile {
  justify-content: space-around;
}