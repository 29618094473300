.contantImg {
  width: 100%;
  height: 58vh;
  object-fit: cover;
}

#map {
  width: 100%;
  height: 58vh;
}

.contact_content {
  margin: 0 auto;
  width: 22.4rem;
}

.contact_content .title {
  margin: 1.333333rem 0 1.866667rem 0;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.contact_content .wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.733333rem;
}

.contact_content .wrap .left p {
  font-size: calc(0.24rem * 1.18);
  font-weight: 200;
  color: #666666;
}

.contact_content .wrap .left .blank {
  margin-bottom: 0.4rem;
}

.contact_content .wrap .right {
  position: relative;
}

.contact_content .wrap .right img {
  position: absolute;
  top: -0.133333rem;
  right: 0;
  width: calc(2.346667rem * 1.18);
  height: calc(2.346667rem * 1.18);
}

@media only screen and (max-width: 1000px) {
  .contact_content {
    padding: 0 0.533333rem;
    margin: 0 auto;
    width: 100%;
  }
}

.csssprite {
  width: 0.44rem !important;
  height: 0.44rem !important;
}