.seminar_content {
  margin: 0 auto;
  width: 22.4rem;
}

.seminar_content .title {
  margin: 1.333333rem 0 0.533333rem 0;
  text-align: left;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.seminar_content p {
  font-size: calc(0.24rem*1.18);
  color: #666666;
  font-weight: 200;
  text-align: left;
}

.seminar_content .blank {
  margin-bottom: 0.533333rem;
}

.seminar_content .img {
  margin: 1.333333rem 0 1.866667rem;
  width: 100%;
  height: 10.266667rem;
}