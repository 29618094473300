.top {
  margin-bottom: 1.866667rem;
}

.mobile_com {
  padding: 0 0.533333rem;
  margin-bottom: 1.066667rem;
}

.mobile_com .title {
  margin: 1.333333rem 0 1.946667rem;
  font-weight: 700;
  font-size: 0.48rem;
  color: #0763a7;
}

.mobile_com .img {
  margin: 1.066667rem 0;
  width: 7.6rem;
  height: 10.24rem;
}

.mobile_com .right {
  text-align: right;
}

.mobile_com .right .img1 {
  margin-bottom: 1.066667rem;
  width: 7.6rem;
  height: 10.24rem;
}

.mobile_com p {
  font-size: 0.24rem;
  font-weight: 200;
  color: #666666;
}