html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
}

.textImg {
  width: 100%;
  height: 74vh;
  object-fit: cover;
}

.workImg {
  width: 100%;
  height: 74vh;
  object-fit: cover;
}

.work_content {
  position: relative;
  text-align: center;
  height: 26vh;
}

.work_content .title {
  padding-top: calc(0.666667rem * 2.28);
  margin-bottom: calc(0.48rem * 2.28);
  color: #0a62a7;
  font-weight: 700;
  font-size: calc(0.48rem*1.18);
  line-height: 0.48rem;
}

.work_content .text {
  padding: 0 0.266667rem;
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.work_content .link-wrap {
  margin-top: 0.4rem;
}

.work_content .link {
  display: block;
  margin: 0 auto;
  padding: 0 0.266667rem;
  min-width: 3.066667rem;
  max-width: 4rem;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  font-size: 0.186667rem  !important;
  color: #ffffff;
  background-color: rgba(0,0,0,0.8);
  border-radius: 0.533333rem;
}

.work_content .link.true {
  max-width: 4.666667rem;
}

@media only screen and (max-width: 1000px) {
  .workImg {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }

  .work_content {
    height: 40vh;
  }
}