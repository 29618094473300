.tea_content {
  margin: 0 auto;
  width: 22.4rem;
}

.tea_content .title {
  margin: 1.333333rem 0 1.866667rem 0;
  text-align: center;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.tea_content .con_img {
  width: 100%;
  height: 5.333333rem;
}

.tea_content .con {
  display: flex;
  justify-content: space-between;
  margin-top: 1.333333rem;
  margin-bottom: 1.866667rem;
  height: 10.4rem;
}

.tea_content .con .dtl {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 5.333333rem;
}

.tea_content .con .dtl .text {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666;
}

.tea_content .con .dtl .img {
  width: 5.333333rem;
  height: 5.333333rem;
  border-radius: 0.053333rem;
}