.culture_content .textImg {
  width: 100%;
  height: 74vh;
  object-fit: cover;
}

.culture_content .head {
  height: 26vh;
  text-align: center;
}

.culture_content .head .title {
  padding-top: calc(0.533333rem * 2.28);
  margin-bottom: calc(0.533333rem * 2.28);
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  line-height: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.culture_content .head .text {
  font-size: calc(0.24rem*1.18);
  font-size: 200;
  color: #666666;
}

.culture_content .img_wrapper {
  position: relative;
}

.culture_content .img_wrapper .t {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: calc(0.96rem * 1.18);
  color: #fff;
}

.culture_content .desc {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.986667rem;
  margin: 0 auto;
  width: 22.4rem;
}

.culture_content .desc .dtl {
  width: 5.333333rem;
}

.culture_content .desc .dtl .title {
  margin: 0.64rem 0 1.173333rem;
  font-size: calc(0.266667rem*1.18);
  color: #0a62a7;
}

.culture_content .desc .dtl p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.culture_content .desc .dtl .img {
  margin-top: 0.64rem;
  width: 5.333333rem;
  height: 5.333333rem;
  border-radius: 100%;
}

.culture_content .desc .dtl ul {
  margin-left: 0.266667rem;
  width: 5.066667rem;
}

.culture_content .desc .dtl ul li {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.b {
  font-weight: 700 !important;
}

.nmt {
  margin-bottom: 0;
}

@media only screen and (max-width: 1000px) {
  .culture_content .textImg {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .culture_content .desc {
    display: block;
    width: 100%;
  }

  .culture_content .desc .dtl {
    padding: 0 0.533333rem;
    width: 100%;
  }

  .culture_content .desc .dtl ul {
    width: 100%;
  }
}