.recruitImg {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}

.recruit_content .poem {
  margin: 1.333333rem auto;
  width: 22.4rem;
}

.recruit_content .poem .top {
  display: flex;
  justify-content: space-between;
}

.recruit_content .poem .top .left {
  position: relative;
  width: 10.666667rem;
  height: calc(5.066667rem * 1.3);
}

.recruit_content .poem .top .left .text {
  text-align: left;
  font-size: calc(0.426667rem * 1.18);
  color: #000000;
}

.recruit_content .poem .top .left .line {
  margin: 0.906667rem 0 0;
  text-align: right;
}

.recruit_content .poem .top .right .img {
  width: calc(7.6rem * 1.3);
  height: calc(5.066667rem * 1.3);
  border-radius: 0.053333rem;
  object-fit: cover;
}

.recruit_content .poem .desc {
  margin-top: 1.066667rem;
  text-align: left;
  font-size: calc(0.24rem * 1.18);
  font-weight: 200;
  color: #666666;
}

.recruit_content .recruit {
  margin: 0 auto;
  width: 22.4rem;
}

.recruit_content .recruit .leftTitle {
  height: 0.306667rem;
  font-size: 0.32rem;
  font-weight: normal;
  color: #0059a5;
  line-height: 0.24rem;
  text-align: center;
  text-shadow: 0 0.026667rem 0.013333rem rgba(55,135,204,0.75);
}

.recruit_content .resume {
  margin: 0 auto;
  width: 22.4rem;
}

.recruit_content .resume .leftTitle {
  margin-bottom: 0.8rem;
  font-size: calc(0.426667rem * 1.18);
  line-height: 0.48rem;
  text-align: left;
  color: #000000;
}

.recruit_content .resume .title1 {
  margin-bottom: 0.666667rem;
  font-size: calc(0.24rem * 1.18);
  color: #666666;
}

.recruit_content .resume .indent {
  text-indent: 1.333333rem;
}

.recruit_content .wraps {
  margin-bottom: 1.333333rem;
  width: 22.4rem;
}

.recruit_content .wraps .list:last-child {
  border-bottom: 0.026667rem solid #e2e3e3;
}

.FAQ {
  margin: 1.333333rem auto 2.666667rem;
  width: 22.4rem;
}

.FAQ .leftTitle {
  margin-bottom: 0.8rem;
  font-size: calc(0.426667rem * 1.18);
  line-height: 0.48rem;
  text-align: left;
  color: #000000;
}

.FAQ .heads .name {
  font-weight: 500;
  font-size: calc(0.24rem * 1.18);
  color: #666666;
}

.FAQ .wraps {
  margin-bottom: 1.333333rem;
  width: 22.4rem;
}

.FAQ .wraps .list:last-child {
  border-bottom: 0.026667rem solid #e2e3e3;
}

.FAQ .wraps .list:nth-child(5) .desc .detail .con .texts:nth-child(2) {
  text-decoration: underline;
}

@media only screen and (max-width: 1000px) {
  .recruit_content .poem {
    padding: 0 0.533333rem;
    margin: 1.333333rem auto;
    width: 100%;
  }

  .recruit_content .poem .top {
    display: block;
    margin-top: 1.6rem;
  }

  .recruit_content .poem .top .left {
    width: 100%;
  }

  .recruit_content .poem .top .right .img {
    width: 100%;
    object-fit: cover;
  }

  .recruit_content .recruit {
    padding: 0 0.533333rem;
    margin: 0 auto;
    width: 100%;
  }

  .recruit_content .recruit .leftTitle {
    margin-top: 0.4rem;
  }

  .recruit_content .resume {
    padding: 0 0.533333rem;
    margin: 0 auto;
    width: 100%;
  }

  .recruit_content .wraps {
    padding: 0 0.533333rem;
    margin-bottom: 1.333333rem;
    width: 100%;
  }

  .FAQ {
    padding: 0 0.533333rem;
    margin: 1.333333rem auto 2.666667rem;
    width: 100%;
  }

  .FAQ .wraps {
    padding: 0 0.533333rem;
    margin-bottom: 1.333333rem;
    width: 100%;
  }
}

.strong {
  font-weight: 700;
  color: #000000;
}