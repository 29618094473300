.innovation_wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.innovation_wrapper ul {
  position: relative;
  top: 0;
}

.innovation_wrapper li {
  width: 100%;
  height: 100vh;
  font-size: calc(0.4rem*1.18);
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  background-color: #efeff0;
}

.innovation_wrapper li:nth-child(2) {
  background-color: #ffffff;
}

.innovation_wrapper .img {
  position: absolute;
  right: 0;
  width: 50%;
}

.innovation_wrapper .img img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.innovation_wrapper .fullImg {
  width: 100%;
  object-fit: cover;
}

.innovation_wrapper .posText {
  position: absolute;
  top: 45%;
  left: 2.466667rem;
  width: 100%;
  text-align: left;
  color: #ffffff;
}

.innovation_wrapper .posText.black {
  color: #000000;
}

.innovation_wrapper .posText .line-1 {
  font-size: calc(0.453333rem*1.18);
  font-weight: 700;
}

.innovation_wrapper .posText .line-2 {
  font-size: calc(0.24rem*1.18);
}

.innovation_wrapper .posText .line-3 {
  margin-bottom: 0.533333rem;
  font-size: calc(0.24rem*1.18);
}

.innovation_wrapper .posText .line-4 {
  font-size: calc(0.186667rem*1.18);
}

.innovation_wrapper .posText .link {
  display: block;
  margin-top: 0.533333rem;
  margin-bottom: 0.666667rem;
  color: #000000;
  font-weight: 700;
  font-size: calc(0.24rem*1.18);
  line-height: 0.186667rem;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.moblie_inn li:nth-child(2) {
  background-color: #ffffff;
}

.moblie_inn .posText {
  padding: 3.866667rem 0 0 0.533333rem;
  height: 40vh;
  box-sizing: border-box;
}

.moblie_inn .posText.black {
  color: #000000;
}

.moblie_inn .posText .line-1 {
  font-size: calc(0.453333rem*1.18);
  font-weight: 700;
}

.moblie_inn .posText .line-2 {
  font-size: calc(0.24rem*1.18);
}

.moblie_inn .posText .line-3 {
  margin-bottom: 0.533333rem;
  font-size: calc(0.24rem*1.18);
}

.moblie_inn .posText .line-4 {
  font-size: calc(0.186667rem*1.18);
}

.moblie_inn .posText .link {
  display: block;
  margin-top: 0.533333rem;
  margin-bottom: 0.666667rem;
  color: #000000;
  font-weight: 700;
  font-size: calc(0.24rem*1.18);
  line-height: 0.186667rem;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.moblie_inn .img .fullImg {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}