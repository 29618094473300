.relation_wrapper .bgcImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.relation_wrapper .loginIn {
  position: absolute;
  top: 35%;
  width: 100%;
}

.relation_wrapper .loginIn .wraps {
  margin: 0 auto;
  width: 4.4rem;
  height: 1.333333rem;
  font-size: calc(0.186667rem*1.18);
}

.relation_wrapper .loginIn .input-title {
  margin-top: 0.213333rem;
  margin-bottom: 0.053333rem;
  width: 4.4rem;
  text-align: center;
  color: #ffffff;
  font-size: calc(0.186667rem*1.18);
}

.relation_wrapper .loginIn .input-in {
  border: 0.026667rem solid #ffffff;
  background: rgba(128,23,23,0);
}

.relation_wrapper .loginIn .sub {
  margin-top: 0.533333rem;
}

.relation_wrapper .loginIn input {
  height: 0.533333rem;
  width: 4.4rem;
  color: #000000;
  font-weight: 700;
  text-indent: 0.133333rem;
  border: 0.013333rem solid #ffffff;
  background-color: #ffffff;
  border-radius: 0.106667rem;
}

.relation_wrapper .container {
  width: 22.4rem;
  margin: 2rem auto;
}

.relation_wrapper .container p {
  margin-bottom: 0;
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.relation_wrapper .container .blank {
  margin-bottom: 0.666667rem;
}

@media only screen and (max-width: 1000px) {
  .relation_wrapper .bgcImg {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .relation_wrapper .loginIn {
    top: 35%;
  }

  .relation_wrapper .container {
    padding: 0 0.533333rem;
    margin: 1.066667rem auto 0;
    width: 100%;
  }
}