.fapWrapper .headss {
  display: flex;
  justify-content: space-between;
  line-height: 0.8rem;
  background-color: #f2f3f5;
  margin-bottom: 0.266667rem;
}

.fapWrapper .headss .name {
  font-size: calc(0.293333rem * 1.18);
  font-weight: 500;
  color: #000000;
}

.fapWrapper .headss .name.choose {
  color: #0059a5;
  font-weight: 700;
}

.fapWrapper .desc {
  padding: 0.133333rem 0.133333rem 0.266667rem;
  padding-left: 0.133333rem;
  margin-bottom: 0.533333rem;
  background-color: #0059a5;
  color: #fff;
  color: #000000;
}

.fapWrapper .desc.second {
  margin-left: 0.666667rem;
}

.fapWrapper .desc .titles.fir {
  margin-top: 0.4rem;
}

.fapWrapper .desc .con {
  display: flex;
  color: #fff;
}

.fapWrapper .desc .con .answer {
  margin-right: 0.133333rem;
  font-size: calc(0.266667rem * 1.18);
}

.fapWrapper .desc .con .texts {
  text-align: left;
  font-size: calc(0.24rem * 1.18);
}

.fapWrapper .desc .con .texts::marker {
  color: #0059a5;
}