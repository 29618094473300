.list .topTitleWrapper .topTitle {
  width: 100%;
  font-size: 0.4rem;
  margin-bottom: 0.133333rem;
  color: #000000;
  font-weight: 700;
  border-bottom: 0.026667rem solid #e2e3e3;
}

.list .topTitleWrapper.right {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

.list .topTitleWrapper .second {
  margin-left: 0.666667rem;
}

.list .heads {
  display: flex;
  justify-content: space-between;
  line-height: 0.8rem;
}

.list .heads.second {
  margin-left: 0.666667rem;
}

.list .heads.left {
  display: flex;
  justify-content: flex-start;
}

.list .heads.right {
  display: flex;
  justify-content: flex-end;
}

.list .heads .name {
  font-size: calc(0.293333rem * 1.18);
  font-weight: 500;
  color: #000000;
}

.list .heads .name.choose {
  color: #0059a5;
  font-weight: 700;
}

.list .heads .direction {
  font-size: calc(0.24rem * 1.18);
  color: #666666;
}

.list .heads .fold {
  margin-right: 0.186667rem;
  font-weight: 700;
  font-size: calc(0.4rem * 1.18);
  color: #000000;
  width: 0.266667rem;
}

.list .heads .fold .arr_r {
  width: 0.266667rem;
  height: 0.266667rem;
}

.list .heads .fold .arr_d {
  width: 0.266667rem;
  height: 0.266667rem;
}

.list .desc {
  padding-left: 0.133333rem;
  margin-bottom: 0.533333rem;
  background-color: #f8f8f9;
  color: #000000;
}

.list .desc.second {
  margin-left: 0.666667rem;
}

.list .desc .titles {
  margin-bottom: 0.186667rem;
  text-align: left;
  font-size: calc(0.293333rem * 1.18);
  font-weight: 500;
  color: #000000;
}

.list .desc .con {
  margin-left: 0.266667rem;
}

.list .desc .con .answer {
  margin-right: 0.133333rem;
  font-size: calc(0.266667rem * 1.18);
  color: #fff;
}

.list .desc .con .textss {
  text-align: left;
  font-size: calc(0.24rem * 1.18);
}

.list .desc .con .textss::marker {
  color: #0059a5;
}

.detail0 {
  text-align: left;
  font-size: calc(0.24rem * 1.18);
}

.detail0.second {
  margin: 0.133333rem 0;
}

@media only screen and (max-width: 1000px) {
  .list .desc {
    background: #f8f8f9;
  }

  .list .desc .con {
    padding: 0 0.533333rem;
    width: 100%;
  }
}