.you_will_get {
  margin: 0.666667rem auto 1.066667rem;
  width: 22.4rem;
}

.you_will_get .get_wrapper {
  display: flex;
  justify-content: flex-start;
}

.you_will_get .get_wrapper .item {
  flex: 1 1;
  width: 2.133333rem;
  height: 6.4rem;
  background: #f8f8f9;
  transition: all 0.8s;
}

.you_will_get .get_wrapper .item .title {
  margin-top: 0.933333rem;
  height: 0.226667rem;
  font-size: calc(0.293333rem * 1.18);
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000203;
  line-height: calc(0.24rem * 1.18);
  text-align: center;
  font-weight: 700;
}

.you_will_get .get_wrapper .item .content {
  height: 1.24rem;
  font-size: 0.266667rem;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #000203;
  line-height: 0.346667rem;
  text-align: center;
}

.you_will_get .get_wrapper .item .content .texts {
  line-height: 0.533333rem;
  font-weight: 500;
}

.you_will_get .get_wrapper .item .content .texts.last {
  line-height: 0.373333rem;
}

.you_will_get .get_wrapper .item .content .texts.subtitle {
  margin-top: 0.186667rem;
}

.you_will_get .get_wrapper .item .box {
  margin: 0.666667rem auto 0.373333rem;
  width: 0.346667rem;
  height: 0.053333rem;
  background: #0059a5;
}

.you_will_get .get_wrapper .item:hover {
  z-index: 100;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  background-color: #0059a5;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.you_will_get .get_wrapper .item:hover .title {
  color: #fff;
}

.you_will_get .get_wrapper .item:hover .content {
  color: #fff;
}

.you_will_get .get_wrapper .item:hover .box {
  background: #fff;
}

.you_will_get .get_wrapper.mobile {
  width: 200%;
  overflow-x: scroll;
}

.you_will_get .get_wrapper.mobile::-webkit-scrollbar {
  display: none;
}

.you_will_get .get_wrapper.mobile .item {
  width: 6.666667rem;
}

.you_will_get .get_wrapper.mobile .item:hover {
  z-index: 100;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  background-color: #0059a5;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.you_will_get .get_wrapper.mobile .item:hover .title {
  color: #fff;
}

.you_will_get .get_wrapper.mobile .item:hover .content {
  color: #fff;
}

.you_will_get .get_wrapper.mobile .item:hover .box {
  background: #fff;
}

.you_will_get.mobile {
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
}

.you_will_get.mobile::-webkit-scrollbar {
  display: none;
}