.competition_content {
  margin: 0 auto;
  width: 22.4rem;
}

.competition_content .content {
  margin-bottom: 2.666667rem;
}

.competition_content .content p {
  font-size: calc(0.24rem * 1.18);
  font-weight: 200;
  color: #666666;
}

.competition_content .title {
  margin: 1.333333rem 0 1.946667rem;
  font-weight: 700;
  font-size: calc(0.48rem*1.18);
  color: #0763a7;
}

.competition_content .top {
  display: flex;
  justify-content: space-between;
}

.competition_content .top .left {
  width: 7.6rem;
}

.competition_content .top .left .img {
  width: 7.6rem;
  height: 10.24rem;
}

.competition_content .top .left p {
  margin-top: 2.4rem;
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
  width: 7.6rem;
}

.competition_content .top .right {
  width: 7.6rem;
}

.competition_content .top .right .img {
  margin-top: 2.4rem;
  width: 7.6rem;
  height: 10.24rem;
}

.competition_content .top .right p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
  width: 7.6rem;
}

.competition_content .bottom {
  display: flex;
  justify-content: space-between;
  margin-top: 5.146667rem;
  margin-bottom: 1.866667rem;
}

.competition_content .bottom p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
  width: 7.6rem;
}

.competition_content .bottom .img {
  width: 7.6rem;
  height: 10.24rem;
}

@media only screen and (max-width: 1000px) {
  .competition_content {
    padding: 0 0.533333rem;
    margin: 0 auto;
    width: 100%;
  }
}