.recruit_title {
  margin: 0.533333rem auto;
}

.recruit_title .title {
  font-size: 0.453333rem;
  font-weight: 700;
  color: #0059a5;
  line-height: 0.24rem;
  text-align: center;
}

.recruit_title .title span {
  margin: 0 0.133333rem;
}

.recruit_title .title .arrow_img {
  width: 0.533333rem;
  height: 0.36rem;
}