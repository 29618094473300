.product_content {
  margin: 0 auto 1.866667rem;
  width: 19.733333rem;
  position: relative;
  top: 0.666667rem;
}

.product_content .img {
  margin-top: 1.866667rem;
  width: 100%;
  height: 10.466667rem;
}

.product_content .title {
  margin-top: 0.8rem;
  margin-bottom: 0.133333rem;
  font-size: calc(0.266667rem*1.18);
  color: #000;
  font-weight: 500;
}

.product_content p {
  margin-bottom: 0;
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.product_content .nav {
  display: flex;
  margin-top: 2.133333rem;
  width: 100%;
  height: 0.533333rem;
}

.product_content .nav .item {
  flex: 1 1;
  text-align: center;
  font-size: calc(0.346667rem*1.18);
  font-weight: 700;
  cursor: pointer;
}

.product_content .nav .item .item_wrap {
  display: inline-block;
}

.product_content .nav .item.active {
  color: #0a62a7;
}

.product_content .nav .item .line {
  height: 0.053333rem;
  background-color: #c9cacb;
}

.product_content .nav .item .line.active {
  background-color: #0a62a7;
}

.product_content .nav .item.mobile {
  font-size: 0.186667rem !important;
}

.product_content .nav.sec {
  margin-top: 0.8rem;
}

.product_content .nav.sec .item {
  text-align: center;
  font-size: calc(0.266667rem*1.18);
  line-height: 0.8rem;
  font-weight: 700;
  color: #666666;
}

.product_content .nav.sec .item.active {
  color: #0a62a7;
}

.product_content .nav.sec .item .line {
  height: 0.026667rem;
  background-color: #c9cacb;
}

.product_content .nav.sec .item .line.active {
  background-color: #0a62a7;
}

.product_content .chart_wrap {
  margin: 1.866667rem auto 0;
  width: 16rem;
}

.product_content .chart_wrap h3,
.product_content .chart_wrap h2 {
  font-size: calc(0.266667rem*1.18);
  color: #000;
}

.product_content .chart_wrap button {
  width: 1.333333rem;
  height: 0.533333rem;
  font-size: calc(0.266667rem*1.18);
  color: #000;
}

.product_content .chart_wrap .title {
  text-align: center;
  font-size: calc(0.293333rem * 1.18);
  font-weight: 500;
  color: #000;
}

.product_content .highcharts-menu-item {
  height: 3vh !important;
}

.product_content .highcharts-button-box {
  position: relative;
  top: -0.266667rem;
}

.product_content .highcharts-title {
  font-size: calc(0.24rem*1.18) !important;
  font-weight: 200 !important;
  color: #666666 !important;
}

.product_content h1 {
  text-align: center;
}

.product_content .product_detail .title {
  margin: 1.333333rem 0 0;
  line-height: calc(0.426667rem*1.18);
  font-weight: 700;
  color: #0763a7;
}

.product_content .product_detail .title.over {
  color: #999;
}

.product_content .product_detail .titles {
  margin-top: 0.8rem;
  margin-bottom: 0.133333rem;
  font-size: calc(0.266667rem*1.18);
  color: #000;
  font-weight: 500;
}

.product_content .product_detail .text {
  font-size: calc(0.24rem*1.18);
  color: #666666;
  font-weight: 200;
}

.product_content .product_detail .text span {
  background-color: yellow;
}

@media only screen and (max-width: 1000px) {
  .product_content {
    padding: 0 0.266667rem;
    margin: 0 auto 1.066667rem;
    width: 100%;
  }

  .product_content .chart_wrap {
    width: 100%;
  }
}

.product_Img {
  width: 100%;
  height: 0.000013rem;
  object-fit: cover;
}