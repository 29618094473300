.scholarship_wrapper {
  margin: 0 auto 2.666667rem;
  width: 22.4rem;
}

.scholarship_wrapper .title {
  margin: 1.333333rem 0 1.066667rem 0;
  text-align: left;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.scholarship_wrapper .img {
  width: 100%;
  height: 5.333333rem;
  object-fit: cover;
}

.scholarship_wrapper .con {
  margin: 1.066667rem 0;
}

.scholarship_wrapper .con p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.scholarship_wrapper .con .right {
  text-align: right;
}

.scholarship_wrapper .intr {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.866667rem;
}

.scholarship_wrapper .intr .left .title {
  margin-top: 0;
  margin-bottom: 0.4rem;
  font-size: calc(0.293333rem*1.18);
  font-weight: 500;
  color: #000000;
}

.scholarship_wrapper .intr .left p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

@media only screen and (max-width: 1000px) {
  .scholarship_wrapper {
    padding: 0 0.533333rem;
    margin: 0 auto;
    width: 100%;
  }
}