.mission_content {
  display: flex;
  margin: 1.333333rem auto 2.266667rem;
  width: 22.4rem;
}

.mission_content.bot {
  margin-bottom: 0;
}

.mission_content.top {
  margin-top: 0;
}

.mission_content .left {
  width: 7.6rem;
  font-weight: 700;
  font-size: calc(0.426667rem*1.18);
  color: #0a62a7;
}

.mission_content .right {
  width: 18.8rem;
}

.mission_content .right p {
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.mission_content .right .title {
  margin-top: 0.666667rem;
  font-size: calc(0.293333rem*1.18);
  font-weight: 500;
  color: #000000;
}

.mission_content .right .title.fir {
  margin-top: 0;
}

.mission_Img {
  width: 100%;
  height: 15.333333rem;
  object-fit: cover;
}

@media only screen and (max-width: 1000px) {
  .mission_Img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }

  .mission_content {
    display: block !important;
    padding: 0 0.533333rem;
    margin: 1.333333rem auto 2.266667rem;
    width: 100%;
  }

  .mission_content .left {
    margin-bottom: 0.4rem;
  }

  .mission_content .right {
    width: 100%;
  }
}