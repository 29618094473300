.footer {
  width: 100%;
  height: 0.933333rem;
  font-size: calc(0.213333rem * 1.18);
  line-height: 0.933333rem;
  text-align: center;
  color: #000000;
  border-top: 0.026667rem solid rgba(103,103,103,0.8);
  background-color: #ffffff;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
}

.footer img {
  position: relative;
  top: -0.04rem;
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.053333rem;
}

.footer .links {
  margin-left: 0.266667rem;
  color: #000000;
  text-decoration: underline;
}

.footer .icp {
  color: #000000;
}

@media only screen and (max-width: 1000px) {
  .footer {
    font-size: calc(0.16rem * .8);
  }
}