* {
  margin: 0;
  padding: 0;
}

.fullpage_wrapper {
  z-index: 1;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.fullpage_wrapper ul {
  position: relative;
  top: 0;
}

.fullpage_wrapper li {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
}

.fullpage_wrapper .carImg {
  width: 100%;
  min-height: 26.666667rem;
  padding-bottom: 0%;
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fullpage_wrapper .fullImg {
  width: 100%;
  object-fit: cover;
}

.fullpage_wrapper .posText {
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.fullpage_wrapper .posText.black {
  color: #000000;
}

.fullpage_wrapper .posText .line-1 {
  padding: 0 0.533333rem;
  margin-bottom: 0.666667rem;
  font-size: calc(0.506667rem * 1.18);
  font-weight: 700;
}

.fullpage_wrapper .posText .line-1.fir {
  padding: 0 0.133333rem;
  display: inline;
  background: #0006;
}

.fullpage_wrapper .posText .line-2 {
  padding: 0 0.533333rem;
  font-size: calc(0.266667rem *1.18);
}

.fullpage_wrapper .posText .line-2.fir {
  padding: 0 0.133333rem;
  display: inline;
  background: #0006;
}

.fullpage_wrapper .posText .line-3 {
  padding: 0 0.533333rem;
  margin-bottom: 0.533333rem;
  font-size: calc(0.24rem *1.18);
}

.fullpage_wrapper .posText .line-3.fir {
  padding: 0 0.133333rem;
  display: inline;
  background: #0006;
}

.fullpage_wrapper .posText .link {
  display: block;
  margin: 0 auto 0.266667rem;
  width: 3.066667rem;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  font-size: calc(0.213333rem * 1.18) !important;
  color: #000000;
  background-color: rgba(255,255,255,0.8);
  border-radius: 0.533333rem;
}

.fullpage_wrapper .posText .link.dark {
  color: #ffffff;
  background-color: rgba(0,0,0,0.8);
}

.fullpage_wrapper .posText .link.fir {
  margin-top: 0.533333rem;
}

.mobile_fullpage .wrap {
  position: relative;
}

.mobile_fullpage .wrap .fullImg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.mobile_fullpage .wrap .posText {
  position: absolute;
  top: 45%;
  width: 100%;
  text-align: center;
  color: #ffffff;
}

.mobile_fullpage .wrap .posText.black {
  color: #000000;
}

.mobile_fullpage .wrap .posText .line-1 {
  padding: 0 0.533333rem;
  margin-bottom: 0.666667rem;
  font-size: calc(0.506667rem * 1.18);
  font-weight: 700;
}

.mobile_fullpage .wrap .posText .line-2 {
  padding: 0 0.533333rem;
  font-size: calc(0.266667rem *1.18);
}

.mobile_fullpage .wrap .posText .line-3 {
  padding: 0 0.533333rem;
  margin-bottom: 0.533333rem;
  font-size: calc(0.24rem *1.18);
}

.mobile_fullpage .wrap .posText .link {
  display: block;
  margin: 0 auto 0.266667rem;
  width: 3.066667rem;
  height: 0.48rem;
  line-height: 0.48rem;
  text-align: center;
  font-size: calc(0.213333rem * 1.18) !important;
  color: #000000;
  background-color: rgba(255,255,255,0.8);
  border-radius: 0.533333rem;
}

.mobile_fullpage .wrap .posText .link.dark {
  color: #ffffff;
  background-color: rgba(0,0,0,0.8);
}

@media only screen and (max-width: 1000px) {
  .fullpage_wrapper {
    position: relative;
    width: 100%;
    overflow: scroll;
  }
}

.resumeUpload {
  position: fixed;
  bottom: 1.066667rem;
  right: 0.4rem;
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 100%;
  color: #000000;
}

.resumeUpload .img {
  display: block;
  margin: 0.133333rem auto;
  width: 1.066667rem;
  height: 1.066667rem;
}

.resumeUpload .title {
  position: relative;
  top: -0.08rem;
  text-align: center;
  font-weight: 700;
}

.modal-title {
  text-align: center;
  font-size: 0.373333rem;
  font-weight: 700;
}