.resume {
  margin: 0.613333rem auto !important;
  width: 22.4rem;
}

.resume .con .con_wrap {
  margin-bottom: 0.32rem;
}

.resume .con .con_wrap .title {
  margin-bottom: 0.16rem;
  font-size: 0.293333rem;
  color: #000000;
}

.resume .con .con_wrap .desc {
  margin-bottom: 0.16rem;
  font-size: 0.293333rem;
  font-weight: normal;
  color: #000000;
}

.resume .con .con_wrap::marker {
  color: #0059a5;
}