.head_wrapper {
  z-index: 20;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  height: 1.333333rem;
  background: rgba(0,0,0,0.1) !important;
}

.head_wrapper.true {
  background: rgba(0,0,0,0.3) !important;
}

.head_wrapper .left {
  height: 0.266667rem;
  font-size: calc(1.333333rem*1.18);
  color: #fff;
}

.head_wrapper .left img {
  position: absolute;
  top: 0.533333rem;
  left: 1.333333rem;
  width: calc(4.666667rem * 1.18);
  height: calc(0.266667rem * 1.18);
}

.head_wrapper .right {
  margin-top: 0.453333rem;
  margin-right: 1.333333rem;
  height: 0.666667rem;
  font-size: 0.266667rem;
  color: #fff;
}

.head_wrapper .right .more {
  width: 0.506667rem;
  height: 0.28rem;
}

.head_wrapper .right a {
  margin-left: 0.4rem;
  display: inline-block;
  margin-bottom: 0.133333rem;
  height: 0.6rem;
  color: #fff;
  font-weight: lighter;
  text-decoration: none;
}

.head_wrapper .right a.active {
  font-weight: 700;
}

.head_wrapper .mobile_nav {
  position: fixed;
  right: -100%;
  width: 50%;
  height: 100vh;
  background-color: #fff;
}

.head_wrapper .mobile_nav.true {
  right: 0;
  transition: all .2s ease-in;
}

.head_wrapper .mobile_nav.false {
  right: -100%;
  transition: all .2s ease-in;
}

.head_wrapper .mobile_nav .head {
  position: relative;
}

.head_wrapper .mobile_nav .head .left .logo1 {
  top: 0.666667rem;
}

.head_wrapper .mobile_nav .head .close {
  position: absolute;
  top: 0.266667rem;
  right: 0.4rem;
}

.head_wrapper .mobile_nav .head .close img {
  width: 0.266667rem;
  height: 0.266667rem;
}

.head_wrapper .mobile_nav .link_wrapper {
  margin-top: 3.2rem;
}

.head_wrapper .mobile_nav a {
  display: block;
  margin-bottom: 0.266667rem;
  font-size: 0.426667rem;
  text-align: center;
  color: #000000;
}

@media only screen and (max-width: 1000px) {
  .head_wrapper.true {
    background: rgba(0,0,0,0.3) !important;
  }

  .head_wrapper .left img {
    left: 0.533333rem;
  }

  .head_wrapper .right {
    margin-right: 0.533333rem;
  }
}