.blank_1 {
  height: 3.866667rem;
  text-align: center;
  color: #0a62a7;
  font-weight: 700;
  font-size: calc(0.48rem*1.18);
  line-height: 3.866667rem;
}

.notion_wrapper {
  margin-bottom: 1.066667rem;
  height: 10.266667rem;
  background-color: #e6e6e7;
}

.notion_wrapper.dark {
  background-color: #221815;
}

.notion_wrapper .notion_content {
  display: flex;
  margin: 0 auto 0.933333rem;
  width: 22.4rem;
  height: 10.266667rem;
  background-color: #e6e6e7;
}

.notion_wrapper .notion_content .left {
  width: 50%;
  font-size: calc(0.4rem*1.18);
  background-color: #e6e6e7;
}

.notion_wrapper .notion_content .left.dark {
  background-color: #221815;
}

.notion_wrapper .notion_content .left.dark .title {
  color: #ffffff;
  font-size: calc(0.533333rem*1.18);
}

.notion_wrapper .notion_content .left.dark p {
  margin-top: 0.266667rem;
  width: 8.4rem;
  text-align: left;
  font-size: calc(0.266667rem*1.18);
  font-weight: 200;
  color: #ffffff;
}

.notion_wrapper .notion_content .left.white .title {
  color: #000;
  font-size: calc(0.533333rem*1.18);
}

.notion_wrapper .notion_content .left.white p {
  margin-top: 0.266667rem;
  width: 8.4rem;
  text-align: left;
  font-size: calc(0.266667rem*1.18);
  font-weight: 200;
  color: #000;
}

.notion_wrapper .notion_content .left .title {
  margin: 1.546667rem 0 0.6rem;
  width: 8.4rem;
  text-align: left;
  font-size: calc(0.533333rem*1.18);
  font-weight: 700;
  color: #000000;
}

.notion_wrapper .notion_content .left .line_1 {
  margin-top: 3.066667rem;
  width: 8.4rem;
  height: 3.066667rem;
  text-align: left;
  font-size: calc(0.266667rem*1.18);
  color: #000000;
}

.notion_wrapper .notion_content .right {
  width: 50%;
}

.notion_wrapper .notion_content .right img {
  width: 100%;
  height: 10.266667rem;
}

.text_area {
  display: flex;
  margin: 0 auto 0.8rem;
  width: 22.4rem;
}

.text_area .left {
  margin-top: 0.533333rem;
  width: 50%;
}

.text_area p {
  width: 9.866667rem;
  font-size: calc(0.32rem*1.18);
  color: #000000;
}

.work_notion {
  margin: 0 auto;
  width: 22.4rem;
}

.work_notion .content {
  display: flex;
  margin-bottom: 0.4rem;
}

.work_notion .content .left {
  color: #000000;
  width: calc(6.666667rem * 1.18);
  font-size: calc(0.48rem * 1.18);
}

.work_notion .content .right {
  width: calc(12.186667rem * 1.18);
}

.work_notion .content .right p {
  margin-bottom: 0.666667rem;
  font-size: calc(0.24rem*1.18);
  font-weight: 200;
  color: #666666;
}

.work_notion .nontion_img {
  margin-bottom: 2.133333rem;
  width: 100%;
  height: calc(5.333333rem * 1.18);
}

.work_notion .img_box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.133333rem;
}

.work_notion .img_box .nontion_img1 {
  width: calc(5.333333rem* 1.18);
  height: calc(5.333333rem* 1.18);
}